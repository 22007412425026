const ScrollTop = (number = 0, time) => {
    if (!time) {
        document.body.scrollTop = document.documentElement.scrollTop = number;
        return number;
    }
    let spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
    let spacingInex = time / spacingTime; // 计算循环的次数
    let nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
    let everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
    let scrollTimer = setInterval(() => {
        if (spacingInex > 0) {
            spacingInex--;
            ScrollTop(nowTop += everTop);
        } else {
            clearInterval(scrollTimer); // 清除计时器
        }
    }, spacingTime);
}
//const scrollToTop =  (duration,to) =>{
//     // cancel if already on top
//     if (document.scrollingElement.scrollTop === 0) return;

//     const cosParameter = document.scrollingElement.scrollTop / 2;
//     let scrollCount = 0, oldTimestamp = 0;

//     function step (newTimestamp) {
//         console.log(oldTimestamp)
//         if (oldTimestamp >= to) {
//             // if duration is 0 scrollCount will be Infinity
//             scrollCount += Math.PI * ( oldTimestamp-newTimestamp) / duration;
//             if (scrollCount >= Math.PI) return document.scrollingElement.scrollTop = 0;
//             document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
//         }
//         oldTimestamp = newTimestamp;
//         window.requestAnimationFrame(step);
//     }
//     window.requestAnimationFrame(step);
// },

export {
    ScrollTop
}
    <!-- 产品服务-->

<template>
    <div class="Proservice">
         <div class="banner-bg">
            <div class="banner">
                <div class="content">
                    <p>数字共谱好生活</p>
                    <span>优选好生活 开放型非标大宗商品交易及服务平台</span>
                </div>
            </div>
        </div>
        <div class="tabs-con">
            <div
                v-for="i in 7"
                :key="i"
                :ref="'tabsContent' + i"
                :class="{ padd: i == 5 }"
            >
                <div class="tabs-box fl-ai-c" :class="{ bg: (i + 1) % 2 }">
                    <tabsContent class="content" :index="i - 1"></tabsContent>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import tabsContent from '@/components/tabsContent'
 import {ScrollTop} from '@/utils/util'
export default {
  name: 'Proservice',
  components: {
      tabsContent
  },
  mounted(){
      if(this.$route.query.type){
          let type = this.$route.query.type;
          let scroll = this.$refs['tabsContent'+type][0].offsetTop
          ScrollTop(scroll,300)
      }
     
  },
  methods:{

  }
}
</script>
<style lang="scss" scoped>
.Proservice {
    margin-bottom: 80px;
}
.banner-bg {
    background: #f1f6ff;
    margin-bottom: 100px;
    height: 412px;
    background-image: linear-gradient(to right, #f1f7ff, #edf3fb);
}
.padd {
    padding: 60px 0;
}
.banner {
    background: url("~@/assets/img/pro-banner.png") no-repeat center;
    background-size: cover;
    height: 482px;
    color: #002062;
    max-width: 1920px;
    margin: 0 auto;
    min-width: 1200px;
    p {
        font-size: 58px;
        font-weight: bold;
        line-height: 62px;
        letter-spacing: 5px;
        padding: 117px 0 22px 0;
    }
    span {
        font-size: 28px;
        font-weight: 400;
        color: #002062;
        line-height: 42px;
        letter-spacing: 2px;
    }
}

.bg {
    background: #f9fcff;
}
.tabs-box {
    min-height: 520px;
    min-width: 1200px;
}
.tabs-con {
    margin-top: -65px;
}
</style>
